import { MonitorHeart, MoreHoriz, RocketLaunch, Send, VisibilityOff } from '@mui/icons-material';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ActionButton, ActionButtonLink } from '~/components/button';
import { SelectAllCellCheckbox } from '~/components/data-grid/SelectAllCellCheckbox';
import { SelectAllHeaderCheckbox } from '~/components/data-grid/SelectAllHeaderCheckbox';
import { DeviceIndicators, DeviceVersion } from '~/components/devices';
import { ActiveShowName } from '~/components/devices/ActiveShowName/ActiveShowName';
import { DeviceName } from '~/components/devices/DeviceName/DeviceName';
import { AuthorizedLink } from '~/components/links';
import { useAppContext } from '~/contexts';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import type { RowSelectionMode } from '~/hooks/data-grid';
import type { DeviceList__Device as Device } from '../../queries/list.generated';
import { formatUptime } from '../../show/lib';
import { Menu } from '../components';

export interface UseColumnsProps {
  handleRowSelectionModeChange: (mode: RowSelectionMode) => void;
  onSendCommand: (selected: DeviceBulkSelectionInput) => void;
  rowSelectionMode: RowSelectionMode;
  selectableCount: number;
}

export const useColumns = ({
  handleRowSelectionModeChange,
  onSendCommand,
  rowSelectionMode,
  selectableCount,
}: UseColumnsProps): GridColDef<Device>[] => {
  const { currentNetwork } = useAppContext();

  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const location = useLocation();

  return useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        align: 'left',
        headerAlign: 'left',
        width: 75,
        renderCell: (params) => (
          <SelectAllCellCheckbox
            {...params}
            handleRowSelectionModeChange={handleRowSelectionModeChange}
          />
        ),
        renderHeader: (params) => {
          if (selectableCount === 0) return null;
          return (
            <SelectAllHeaderCheckbox
              {...params}
              handleRowSelectionModeChange={handleRowSelectionModeChange}
              selectableCount={selectableCount}
              rowSelectionMode={rowSelectionMode}
            />
          );
        },
      },
      {
        field: 'indicators',
        headerName: '',
        sortable: false,
        resizable: false,
        width: 125,
        renderCell: ({ row }) => <DeviceIndicators device={row} />,
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 65,
        resizable: false,
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        minWidth: isSmallAndUp ? 180 : 0,
        renderCell: ({ row }) => <DeviceName device={row} />,
      },
      {
        field: 'group',
        flex: 1,
        headerName: 'Group',
        minWidth: 100,
        resizable: false,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Device>) => (
          <AuthorizedLink authorized={currentNetwork.canManage.value} entity={row.group} />
        ),
      },
      {
        field: 'activeShow',
        flex: 1,
        headerName: 'Now Playing',
        minWidth: 150,
        resizable: true,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Device>) => (
          <ActiveShowName activeShow={row.activeShow} />
        ),
      },
      {
        field: 'ipAddress',
        headerName: 'IP Address',
        minWidth: 120,
        resizable: false,
        sortable: false,
      },
      {
        field: 'playerVersion',
        headerName: 'Version',
        minWidth: 150,
        renderCell: ({ row }) => <DeviceVersion device={row} />,
        resizable: false,
        sortable: true,
      },
      {
        field: 'processUptime',
        headerName: 'Software Uptime',
        minWidth: 150,
        valueGetter: (_, row) => formatUptime(row.processUptime),
        resizable: false,
        sortable: true,
      },
      {
        field: 'osUptime',
        headerName: 'Hardware Uptime',
        minWidth: 150,
        valueGetter: (_, row) => formatUptime(row.osUptime),
        resizable: false,
        sortable: true,
      },
      {
        field: 'internal',
        headerName: '',
        minWidth: 120,
        resizable: false,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <>
              {row.internal === true && (
                <Chip
                  icon={<VisibilityOff />}
                  label="Internal"
                  color="primary"
                  sx={{ mr: '8px' }}
                />
              )}
              {row.demo === true && <Chip icon={<RocketLaunch />} label="Demo" color="tertiary" />}
            </>
          );
        },
      },
      {
        align: 'right',
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        resizable: false,
        getActions: ({ row }) => {
          const actions = [];

          if (row.canUpdate.value)
            actions.push(
              <ActionButton
                key={`send-command-${row.id}`}
                title="Send Command"
                Icon={Send}
                onClick={() => onSendCommand({ ids: [row.id] })}
              />,
            );

          actions.push(
            <ActionButtonLink
              key={`dashboard-${row.id}`}
              title="Dashboard"
              Icon={MonitorHeart}
              to={{ pathname: `${row.id}/details`, search: location.search }}
            />,
          );
          actions.push(
            <Menu
              key={`more-action-${row.id}`}
              device={row}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          );
          return actions;
        },
        sortable: false,
      },
    ],
    [
      currentNetwork,
      handleRowSelectionModeChange,
      isSmallAndUp,
      location.search,
      onSendCommand,
      rowSelectionMode,
      selectableCount,
    ],
  );
};
