import { MoreVert, SupervisorAccount } from '@mui/icons-material';
import { IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { type ElementType } from 'react';
import { useNavigate } from 'react-router-dom';
import { useImpersonate } from '~/api/users';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type NetworkUsers__NetworkMembership as NetworkMembership } from '../../queries/list.generated';

export interface MenuProps {
  buttonType?: 'action' | 'icon';
  networkMembership: NetworkMembership;
  Icon?: ElementType;
}

export const Menu = ({ buttonType = 'icon', networkMembership, Icon = MoreVert }: MenuProps) => {
  const { currentNetwork } = useAppContext();
  const navigate = useNavigate();

  const [moreMenuProps, moreActionProps, moreTableActionProps] =
    useMoreActions<NetworkMembership>();

  const userActionProps = moreTableActionProps(networkMembership);

  const [confirmImpersonate, confirmImpersonateProps] = useConfirmDialog();

  const [impersonate, { client }] = useImpersonate();

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={userActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...userActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        {currentNetwork.canManage.value ? (
          <Tooltip
            title={
              moreMenuProps.context?.canImpersonateUser.value
                ? ''
                : moreMenuProps.context?.canImpersonateUser.reasons?.fullMessages[0] ??
                  'You do not have permission to sign in as this user'
            }
          >
            <span>
              <MoreAction
                {...moreActionProps}
                Icon={SupervisorAccount}
                disabled={!moreMenuProps.context?.canImpersonateUser.value}
                title="Sign in as user"
                onClick={async () => {
                  const id = moreMenuProps.context?.user.id;
                  assert(id != null, 'no user id');
                  if (!(await confirmImpersonate())) return;
                  await impersonate({ variables: { input: { id } } });
                  await client.resetStore();
                  navigate('../dashboard');
                }}
              />
            </span>
          </Tooltip>
        ) : null}
      </MuiMenu>
      <ConfirmDialog
        {...confirmImpersonateProps}
        confirm="Continue"
        prompt="When logging in as another user, any action performed will be recorded as you."
        title="Sign in as user"
      />
    </>
  );
};
